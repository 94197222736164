import Vue from "vue";
import Router from "vue-router";
// import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
  // mode: "history",
  // base: process.env.BASE_URL,
  // // This is for the scroll top when click on any router link
  // scrollBehavior: (to, from, savedPosition) => {
  //     let scrollTo = 0;

  //     if (to.hash) {
  //         scrollTo = to.hash;
  //     } else if (savedPosition) {
  //         scrollTo = savedPosition.y;
  //     }

  //     return goTo(scrollTo);
  // },

  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "authentication/boxedlogin",
      // component: () =>
      //     import ("@/layouts/blank-layout/Blanklayout"),
      // children: [{
      //     name: "BoxedLogin",
      //     path: "boxedlogin",
      //     component: () =>
      //         import ("@/views/authentication/BoxedLogin"),
      // }, ]
    },

    {
      path: "/dashboards",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "customers",
          path: "customers",
          component: () => import("@/views/customers/show"),
        },

        // customers
        {
          name: "customersAdd",
          path: "customers/add",
          component: () => import("@/views/customers/add"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        {
          name: "customersEdit",
          path: "customers/edit",
          component: () => import("@/views/customers/edit"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        // teachers
        {
          name: "teachers",
          path: "teachers",
          component: () => import("@/views/teachers/show"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        // drivers
        {
          name: "drivers",
          path: "drivers/show",
          component: () => import("@/views/drivers/show"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        {
          name: "driversAdd",
          path: "drivers/show/add",
          component: () => import("@/views/drivers/add"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        {
          name: "driversEdit",
          path: "drivers/show/edit",
          component: () => import("@/views/drivers/edit"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        // driversStudents
        {
          name: "driversStudents",
          path: "drivers/students",
          component: () => import("@/views/drivers/students"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        // courses
        {
          name: "courses",
          path: "courses",
          component: () => import("@/views/courses/show"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        // new_courses
        {
          name: "new_courses",
          path: "new_courses",
          component: () => import("@/views/new_courses/show"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        // subscriptions
        {
          name: "subscriptions",
          path: "subscriptions",
          component: () => import("@/views/subscriptions/show"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        // expenses
        {
          name: "expenses",
          path: "expenses/show",
          component: () => import("@/views/expenses/show"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        {
          name: "expensesType",
          path: "expenses/type",
          component: () => import("@/views/expenses/type"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        // other payments
        {
          name: "otherPayments",
          path: "otherPayments",
          component: () => import("@/views/otherPayments"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },
        // payment
        {
          name: "payment",
          path: "subscriptions/payment",
          component: () => import("@/views/subscriptions/payment"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },

        // notifications
        {
          name: "notifications",
          path: "notifications",
          component: () => import("@/views/notifications/show"),
          beforeEnter(to, from, next) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              next("/");
            }
          },
        },
      ],
    },

    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "BoxedLogin",
          path: "boxedlogin",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        {
          name: "print",
          path: "print",
          component: () => import("@/views/subscriptions/print"),
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },

    // {
    //     path: "*",
    //     component: () =>
    //         import ("@/views/Error"),
    // },
  ],
});

// import NProgress from "nprogress";

// router.beforeResolve((to, from, next) => {
//     // If this isn't an initial page load.
//     if (to.name) {
//         // Start the route progress bar.
//         NProgress.start(800);
//     }
//     next();
// });

// router.afterEach(() => {
//     // Complete the animation of the route progress bar.
//     NProgress.done();
// });

export default router;
