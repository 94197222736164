import axios from 'axios'
import sha512 from 'js-sha512'

class Api {
    async login(phone, account_password, auth_phone_details, auth_ip, auth_city, auth_lat, auth_lon) {
        return axios
            .post('/login', {
                mobile: phone,
                password: sha512(account_password),
                auth: {
                    auth_phone_details: auth_phone_details,
                    auth_ip: auth_ip,
                    auth_city: auth_city,
                    auth_lat: auth_lat,
                    auth_lon: auth_lon,
                }

                // auth_phone_id: authData.firebaseToken,
                // auth_firebase: authData.firebaseToken,
            })
            .then(Response => ({ error: false, results: Response }))
            .catch(() => ({ error: true, results: [] }))
    }

    async logout() {
        axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        return axios
            .get('/logout')
            .then(Response => {
                if (Response.error === false) {
                    return { error: false, results: Response }
                }

                return { error: true, results: Response }
            })
            .catch(() => ({ error: true, results: [] }))
    }
}

export default new Api()